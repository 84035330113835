.detail-item {
    &__title {
        font-size: 18px;
        font-weight: 600;
        color: #00d;
        margin-top: 0;
    }

    &__info {
        font-size: 14px;
        font-weight: 600;
        color: #FF5722;
    }

    &__author {
        font-size: 14px;
    }

    &__subtitle {
        font-size: 14px;
    }
}
