
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600,900);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "helpers";

// Components
@import "components/list-group";
@import "components/header";
@import "components/detail-item";


.nav {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    &-left {
        flex: 2;
    }
    &-right {
        font-size: 14px;
        text-align: left;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 4px solid $brand-primary;
    }
}

.nav-brand {
    font-size: 62px;
    font-weight: 900;
    padding-right: 2rem;
    display: block;
}

.newsletter-registration {
    display: block;
    text-align: center;
    border: 1px solid #eee;
    padding: 12px;
    min-width: 300px
}

.newsletter-registration h3 {
    font-weight: 700;
    font-size: 22px;
    color: #c9302c;
    margin: 0;
    letter-spacing: 1px
}

.newsletter-registration p {
    margin: 0 0 5px;
    font-size: 16px;
    color: #c9302c
}

.newsletter-registration .signup-btn {
    text-transform: uppercase;
    letter-spacing: 1px
}

section.sep {
    height: 20px;
    background-color: $brand-primary;
    margin-bottom: 2rem;
}

.panel {
    border-radius: 0;
    border: 0;
    background-color: #e6e6e6;
    .panel-heading {
        border-radius: 0;
        font-size: 24px;
        font-weight: 900;
        text-align: center;
        padding: 7px 10px;
    }
    .panel-body {
        .title {
            color: #f57c00;
            font-weight: 900;
        }
    }
}

.panel-image {
    margin: 0;
    overflow: hidden;
}

.panel-1 {
    .panel-heading {
        color: #ffffff;
        background-color: #b3a63c;
    }
}
.panel-2 {
    .panel-heading {
        color: #ffffff;
        background-color: #f57c00;
    }
}
.panel-3 {
    .panel-heading {
        color: #ffffff;
        background-color: #50ba96;
    }
}
.panel-4 {
    .panel-heading {
        color: #ffffff;
        background-color: #5563a7;
    }
}


.btn-survey {
    color: $brand-primary;
    font-weight: 600;
}
