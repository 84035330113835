.list-group {
    border: 0 none;
}

.list-group-item {
    font-size: 16px;
    margin-bottom: 4px;
    color: #fff;
    border: 0 none;
    border-radius: 4px;

    &:hover {
        color: #fff;
    }

    &.active {
        font-weight: 700;
    }

    &.is-symposium {
        background-color: $brand-symposium;
        color: #fff;
        &:hover {
            background-color: lighten($brand-symposium, 10%);
            color: #fff;
        }
    }

    &.is-trial {
        background-color: $brand-trial;
        color: #fff;
        &:hover {
            background-color: lighten($brand-trial, 10%);
            color: #fff;
        }
    }

    &.is-survey {
        background-color: $brand-survey;
        color: #fff;
        &:hover {
            background-color: lighten($brand-survey, 10%);
            color: #fff;
        }
    }

    &.is-awareness {
        background-color: $brand-awareness;
        color: #fff;
        &:hover {
            background-color: lighten($brand-awareness, 10%);
            color: #fff;
        }
    }


}
