.page_header {
    &__title {
        margin-top: 0;
        font-weight: bold;

        .is-symposium & {
            color: $brand-symposium;
        }

        .is-trial & {
            color: $brand-trial;
        }

        .is-survey & {
            color: $brand-survey;
        }

        .is-awareness & {
            color: $brand-awareness;
        }
    }
}
